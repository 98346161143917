.live-debug-page {
  .kanban-board {
    min-height: 600px;
  }
  .logs-list {
    padding: 8px;
    .log-event {
      display: flex;
      font-family: monospace;
      margin-bottom: 0;
      &.event-type-warn {
        > .date {
          color: #ffab00 !important;
        }
      }
      &.event-type-error {
        > .date {
          color: #ff5630 !important;
        }
      }
      &:hover {
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.3);
      }
      > span {
        display: inline-block;
        &.description {
          flex: 1;
        }
      }
    }
  }
}
