.ring-container {
  position: relative;
  height: 25px;
  width: 25px;
}

.circle {
  width: 15px;
  height: 15px;
  background-color: rgb(255, 86, 48);
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 5px;
}

.ringring {
  border: 3px solid rgb(255, 86, 48);
  border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.0
}
@keyframes pulsate {
    0% { transform: scale(0.1, 0.1); opacity: 0.0; }
    50% { opacity: 1.0; }
    100% { transform: scale(1.2, 1.2); opacity: 0.0; }
}
